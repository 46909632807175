require('flickity-imagesloaded');
require('flickity-fullscreen');

import Flickity from 'flickity';
import 'flickity/css/flickity.css';
import 'flickity-fade/flickity-fade.css';
import 'flickity-fade/flickity-fade';
import TouchSweep from "touchsweep";


const slideshows = document.querySelectorAll(".carousel");

slideshows.forEach((elem) => {
    var flkty = new Flickity(elem, {
        cellAlign: 'center',
        contain: true,
        lazyLoad: 2,
        autoPlay: 2500,
        pauseAutoPlayOnHover: false,
        fade: true,
        selectedAttraction: 1,
        friction: 1,
        wrapAround: true,
        pageDots: false,
        arrowShape: 'M57.99 74.41L32.81 49.23L57.99 24.04L58.88 24.92L34.58 49.23L58.88 73.53L57.99 74.41z',
        on: {
            ready: function () {
                const area = this.element;
                const data = {
                    value: 1
                };
                const touchThreshold = 20;
                const touchSweepInstance = new TouchSweep(area, data, touchThreshold);

                console.log(touchSweepInstance);

                area.addEventListener('swipeleft', event => {
                    event.preventDefault();
                    flkty.next(true);
                    flkty.stopPlayer();
                });
                area.addEventListener('swiperight', event => {
                    event.preventDefault();
                    flkty.previous(true);
                    flkty.stopPlayer();
                });
                /*area.addEventListener('swipeup', event => {
                    event.preventDefault();
                    flkty.next(true);
                    flkty.stopPlayer();
                });
                area.addEventListener('swipedown', event => {
                    event.preventDefault();
                    flkty.previous(true);
                    flkty.stopPlayer();
                });*/
            }
        }
    });

    function changeColor() {
        const activeSlide = flkty.selectedCell.element;
        const color = activeSlide.dataset.background;
        const txtcolor = activeSlide.dataset.color;
        document.body.style.setProperty('--bg-color', color);
        document.body.style.setProperty('--txt-color', txtcolor);


    }

    function updateCount() {
        const activeSlideNb = flkty.selectedCell.element.dataset.index;
        const slideCounter = document.querySelector('.slide-counter .count');

        slideCounter.innerHTML = activeSlideNb;

    }

    function updateLegend() {
        const legendContainer = document.querySelector(".slide-legend");
        const activeSlide = flkty.selectedCell.element;
        const slideLegend = activeSlide?.dataset.legend;
        const hasbkg = activeSlide.hasAttribute('has-background') ? "has-background" : null;
        if (legendContainer) {
            if (hasbkg) {
                legendContainer.classList.add(hasbkg);
            } else {
                legendContainer.classList.remove('has-background');
            }
            legendContainer.innerHTML = slideLegend;
        }
    }

    flkty.on('lazyLoad', function () {
        updateLegend();
        changeColor();
        updateCount();
    });
    flkty.on('change', function () {
        changeColor();
        updateLegend();
        updateCount();
    });

    const buttons = document.querySelectorAll('.flickity-button');

    buttons.forEach((elem) => {
        elem.addEventListener('click', () => {
            flkty.playPlayer();
        });
    });

    const aboutToggler = document.querySelector("a[href='/about']");
    aboutToggler.addEventListener('click', () => {
        document.body.style.setProperty('--bg-color', "white");
        document.body.style.setProperty('--txt-color', "black");
        flkty.destroy();
    });

});
