__webpack_public_path__ = "http://dev.local:8080/assets/";

import "../scss/application.scss";
import { onLoading } from "./_modules/website";
import { loadPage } from "./_modules/home";
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import './_modules/slideshow';





document.addEventListener("DOMContentLoaded", () => {
  onLoading();
  loadPage();



  const aboutToggler = document.querySelector("a[href='/about']");
  const aboutContainer = document.querySelector('.about-container');

  aboutToggler.addEventListener('click', (e) =>{
    e.preventDefault();
 /*   aboutToggler.classList.add('active');*/
    aboutContainer.style.opacity = '1';
    aboutContainer.style.pointerEvents ='auto';

  });

  document.addEventListener("gesturestart", function (e) {
    e.preventDefault();
    document.body.style.zoom = 0.99;
  });

  document.addEventListener("gesturechange", function (e) {
    e.preventDefault();

    document.body.style.zoom = 0.99;
  });
  document.addEventListener("gestureend", function (e) {
    e.preventDefault();
    document.body.style.zoom = 1;
  });

});